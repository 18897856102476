/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./layout.css"
import Header from "../components/header"

const Layout = ({ children }) => {
  const head = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)

  return (
      <div className="pancake"
        style={{
          margin: `0`,
        }}
      >
        <Header siteTitle={head.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        <footer style={{
          background: `white`,
          textAlign: 'center',
          padding: '1% 0',
        }}>
          <div style={{ padding: '2%' }}>
            <Link to="/page-2/">Privacy Policy</Link>  
          </div>
          <div>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://samanyuneelson.works/">Gatsby</a>
          </div>
        </footer>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
