import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FaBars } from 'react-icons/fa'
import { IconContext } from "react-icons"

import "../styles/style.sass"
import "../styles/style.css"

const Header = ( ) => {

  const HeaderSelector=[
    {
      name:"Home",
      link:"/",
    },
    {
      name:"Core Concepts",
      link:"/#concept",
    },
    {
      name:"The Catalysts",
      link:"/#team",
    },
    {
      name:"Social",
      link:"/#contact",
    },
  ]

  const [mobl,mobval] = useState("navbar-list")

  return(
    <nav className= "navbar navbar-top" >
          {/* <Link className="logo" to="/"></Link> */}
          <div className="logo" >
            <h2>HR as Catalyst</h2>
          </div>
          <div className= {mobl}>
                {HeaderSelector.map((contents) => (
                  <Link onClick={() => {
                    mobval((mobl === "navbar-list responive") ? "navbar-list" : "navbar-list responive" )
                  }} to={contents.link}>{ contents.name }</Link>
                ))}
          </div>  
            <IconContext.Provider value={{ className: "react-icons" }}>
              <div className="icon">
                <div  onClick= {() => {
                  mobval((mobl === "navbar-list") ? "navbar-list responive" : "navbar-list")
                  }}>
                  <FaBars />
                </div>
              </div>
            </IconContext.Provider>
      </nav>
  )
}



Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
